<template>
  <div>共同要修改的样式</div>
</template>

<script>
export default {
}
</script>

<style>

</style>